<template>
  <div class="user">
    <div class="user-con">
      <div><router-link tag="a" to="/users" class="get-users">USERS</router-link></div>
      <div><router-link tag="a" to="/adduser" class="get-add-user">ADD USER</router-link></div>
    </div>
    <div class="user-table">
      <table border="0" style="width: 70%">
        <tr>
          <td><h3>User Name</h3></td>
          <td><h3>Create Date</h3></td>
          <td><h3>Permissions</h3></td>
          <td><h3>Department</h3></td>
        </tr>
        <tr>
          <td colspan="4"><hr style="width: 100%" /></td>
        </tr>
         <tr >
          <td style="padding: 10px; border-bottom: 1px solid">samet@sempeak.com</td>
          <td style="border-bottom: 1px solid">23.07.2021</td>
          <td style="border-bottom: 1px solid">Admin</td>
          <td style="border-bottom: 1px solid">CRO</td>
        </tr>
        <tr v-for="item in 5" :key="item.index">
          <td style="padding: 10px; border-bottom: 1px solid">ismail@sempeak.com</td>
          <td style="border-bottom: 1px solid">23.07.2021</td>
          <td style="border-bottom: 1px solid">User</td>
          <td style="border-bottom: 1px solid">CRO</td>
        </tr>
      </table>
    </div>
  </div>
</template>


<script>
export default {};
</script>

<style scoped>
td{
  border-right: 1px solid;
  text-align: center;
}
.user {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}
.user-con {
  display: inline-grid;
  justify-content: flex-end;
  margin-right: 9%;
  margin-top: 5vh;
 
}
.user-con>div{
    margin-bottom: 25px;
}
.user-con a{
    text-decoration: none;
    color: white;
    font-weight: 500;
    background-color: #474959;
    padding: 5px 5px;
    cursor: pointer;
}
.user-table {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20vh;
}
</style>